import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`Header`}</inlineCode>{` merupakan sebuah struktur yang terdiri dari banyak unsur UI terletak di bagian paling atas sebuah halaman, biasanya berisi informasi dan fungsi utama.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <h3>{`Bhinneka B2C`}</h3>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={12} colLg={12} noGutterSm mdxType="Column">
    <ImageCard titleColor="dark" aspectRatio="2:1" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAACQElEQVQoz32Q20uTYRzH37+g626qqwiqi6iLiKAgSrwwEE3REBRcxNJGJYHkhXZSLDtYYY0ynUricvPUZA63Nbe2ice5DqKY2UWWylae5ubh0/O8rgMEPfB5n+/v87zv733fn7IUibEsiMRRc3SVleiaSkTusU0icffnbJXIyibyOdlLkZfF5ShhIUMra3wX9Vx4gdnQAjOheWZF/vItxPRMSM2qi5/NhRcJzy8T/rEkesQbGlqc7E3Ukp2kITshh7RUHYZWByarB3OXF7PNi6nrjYrMKsK32/3UNNsoultPqd7EnkQNDW0OlCaLh6OnC0k/U0JKThHJucVkXrxNxoVbZApS8krj3CQ1v0xQqnJK5GTtDZU0XTkHUy/R1OlBGR79xDOjDVNLN0bxRmOrk/63Ewy9/6gy/GGSvpExFZl/ecnAuwledLh43txNjclGYHQSpaK6FWVbAgd2nmD/9iPs2p1EbtEDzl99jO6annPFVSI/IV+QJ3JeSdVvp7lSydZDWWzZl46y4zh3qs0owbHP1LW9xmDr5anVj6HTi77RyqMGCw/rX3G/toNy/UuVSkMH92rahWtXd1nXmuzUib+SXyl7KY0WN4fTCtBl6NClaNFkF9Lm6MXhHcbhC+D0j2AXWSKzRHpXbxCrq5+sggpOnr3OsazLNFl6UManvmLtGcTnHsDn6sPtGWJQzCcg5hUQ8/0fco5OfxC7bwSre5DxqWmUaGwdudbiyCq2uk5UEvtrj/3r5H0bG7DB5pLuJ9jcesJRsOhWAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/07cf96e08570b339e7b5650301cfd196/3cbba/b2c.png",
              "srcSet": ["/static/07cf96e08570b339e7b5650301cfd196/7fc1e/b2c.png 288w", "/static/07cf96e08570b339e7b5650301cfd196/a5df1/b2c.png 576w", "/static/07cf96e08570b339e7b5650301cfd196/3cbba/b2c.png 1152w", "/static/07cf96e08570b339e7b5650301cfd196/60356/b2c.png 1360w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <h3>{`Bhinneka Business`}</h3>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={12} colLg={12} noGutterSm mdxType="Column">
    <ImageCard titleColor="dark" aspectRatio="2:1" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAACXElEQVQoz32QWUiUURiG/7roPqobCcKy6MKoC6HSECxCJwtapqhEy2ixNA01ISLEsJnUdNRIHU1nnMxldBZnXEJzQZRRKUQnKTMhqZvKXXOp0afzjyUV1IGH9zvne8/L+Y40O/+d+W+uP1lwMSeQezJzC8v8vv/bK9dyT5qYnuXT6BQjY1N8WWGSsYkZpmbmBPOMjk8xOjHtruWzscmvfBY+mZHxaTfyHTlL0hTbWbNZgZ+3gt1bg/DZG8Sug34Eh8eTkm/iXk45qtyKFTQ6C8euJrN62xEkTwWKi0mE38pmlVcgWcU2pOrnXSijVITFpRMa+4DzCRmExKUSdjODMzdSORGlFn21UBXK6/cFy3tZj0eqhDedK3cecfSaCjlLevFqiIciuaLUTukTGyVGOwaTiZpmB/2DH3AODNPTP+TG+XaY10MfaWjv4aGhhryyerTlz5Yps/NSZElqrQlprS9eHvvwXO/Lpu2+bPTZiXfgOULEi09Fq90aEpvG6ZgU9wQ7giOR1vkjeRxA2nJoefwN/qjzKpF637xHb22hoLaD/LoOiuwOtJUOCoxNpD02C1MViZklJGaVoNZWkZxjFH8lphB39OYmCisbKalupdjSjJwlldW1E6CMJe5sDDHKaCIjoolIuMDdrHzauvtp6ewV9Lm1tctJV+8AaQUm9pyMZ3/obYIvJXH4chIBIfGU17YhdTvfoSm0oC+uRqezontqpchowmBuxNLYibnBgVWojKWh0/3x8us0OhvZBju5pfVuMvU2uvsGkVyLS/xrLYnW0i/9Wf/HjmsRfgACHVrX3eqpdgAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/a79ac0541596c11bd8630000646c2b71/3cbba/b2b.png",
              "srcSet": ["/static/a79ac0541596c11bd8630000646c2b71/7fc1e/b2b.png 288w", "/static/a79ac0541596c11bd8630000646c2b71/a5df1/b2b.png 576w", "/static/a79ac0541596c11bd8630000646c2b71/3cbba/b2b.png 1152w", "/static/a79ac0541596c11bd8630000646c2b71/60356/b2b.png 1360w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      